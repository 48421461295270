:root {
  --color: #B0C4DE;
  --background-color: #333333;
  --svg-color-light: var(--color);
  --header-height: max(8vh, 4rem);
}

* {
  color: var(--color);
}

body {
  background-color: var(--background-color);
}

#header-frame {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--header-height);
  border-bottom: 2px solid;
  background-color: var(--background-color);
}

#header {
  height: var(--header-height);
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 0 2vw;
}

#header .wolfcraft-io {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

@media (max-width: 40rem) {
  #header a.wolfcraft-io .name {
    display: none;
  }
}

#header .wolfcraft-io .logo {
  margin: auto 0;
  height: calc(var(--header-height) * 0.8);
}

#header .wolfcraft-io .name {
  margin: auto 1rem;
  height: 2vh;
  min-height: 2rem;
}

#header .social-media-link {
  width: 2vmin;
  min-width: 2rem;
  margin: auto max(1vw, 0.75rem);
}

#content {
  margin-top: calc(var(--header-height) + 10px);
  margin-bottom: 5vh;
  min-height: calc(80vh - var(--header-height));
}

#footer {
  flex: 1;
  display: flex;
  animation-name: footer-fade-in;
  animation-timing-function: linear;
  animation-duration: 10s;
}

#footer a.application-source,
#footer > .application {
  margin: 4vh auto;
}

#footer .application {
  height: 3em;
}

@keyframes footer-fade-in {
  from {
      opacity: 0;
  }
  to {
    opacity: 1;
  }
}