.photo img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    object-fit: contain;
}

.photo.pre-load {
    opacity: 0;
}

.photo.fade-in {
    animation-name: photo-fade-in;
    animation-duration: 5s;
    animation-timing-function: ease;
}

@keyframes photo-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}